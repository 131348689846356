<template>
    <section>
      <pm-Breadcrumb :home="home" :model="itemsBread" />
      <div class="col-md-12 alinhamento">
        <div class="row">
          <div class="col-md-8">
            <h2 class="titulo-links-escolha">Calendário de Vacinação</h2>
          </div>
        </div>
        <calendarioVacinacao
            modelo="0"
            individual="1"
            :objeto="true"
            @buscarRelatorio="buscarRelatorio"
            @limparFiltro="limparFiltro"
            :filtro="filtro"
        />
      </div>
      <button
        v-if="info.escola != 'Rede de Ensino'"
        style="margin-left: 5px;"
        class="btn btn-danger button-generate"
        :disabled="carregandoPrint"
        @click="pdf()"
        :title="carregandoPrint ? 'Aguarde...' : 'Gerar Impressão'"
      >
        <span v-if="!carregandoPrint">
          <i class="fa fa-file-pdf-o"></i> Pdf
        </span>
        <span v-else>
          Aguarde...
        </span>
      </button>


     
      <div>
        <div v-if="(funcaoDoUsuario == 1 || funcaoDoUsuario == 2) || (funcaoDoUsuario == 3 || funcaoDoUsuario == 4) && info.escola !== 'Rede de Ensino'" style="padding-top: 35px;">
          <div class="mb-3">
            <h2 class="col-12 flex justify-content-center m-0 header" style="font-size: 34px;">{{info.escola}}</h2>
            <h2 class="col-12 flex justify-content-center m-0 header" style="font-size: 25px;">{{info.segmento}}</h2>
            <h2 class="col-12 flex justify-content-center m-0 header" style="font-size: 25px;">{{info.serie}}</h2>
            <h2 class="col-12 flex justify-content-center m-0 header" style="font-size: 25px;">{{info.turma}}</h2>
          </div>
          <div style="padding-top: 5px;">
            <va-chart style="height: 600px;" :data="chartData" type="donut"/>
          </div>
        </div>
        <br><br>
      </div>
      
      <div id="print-vacinacao-calendario">
        <br>
        <div class="mb-3"  v-if="carregandoPrint">
            <h2 class="col-12 flex justify-content-center m-0 header" style="font-size: 17px;">Calendário de Vacinação</h2>
            <h2 class="col-12 flex justify-content-center m-0 header" style="font-size: 15px;">{{info.escola}}</h2>
            <h2 class="col-12 flex justify-content-center m-0 header" style="font-size: 13px;">{{info.segmento}}</h2>
            <h2 class="col-12 flex justify-content-center m-0 header" style="font-size: 13px;">{{info.serie}}</h2>
            <h2 class="col-12 flex justify-content-center m-0 header" style="font-size: 13px;">{{info.turma}}</h2>
        </div>
        <div v-if="info.escola != 'Rede de Ensino'">
          <head>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
            <meta charset="utf-8">
          </head>
          <table class="table" >
            <thead>
              <tr style="text-align: center;">
                <th></th>
                <th>Matrícula</th>
                <th>Aluno</th>
                <th>Escola</th>
                <th>Segmento</th>
                <th>Série</th>
                <th>Turma</th>
                <th>Atualizado</th>
              </tr>
            </thead>
            <tbody v-if="listOfStudents">
              <tr v-for="(student,index) in listOfStudents" :key="student">
                <td style="text-align: center;">{{ index+1 }}</td>
                <td style="text-align: center;">{{ student.matricula }}</td>
                <td style="text-align: center;">{{ student.nome_completo.toUpperCase() }}</td>
                <td style="text-align: center;">{{ student.nome_escola.toUpperCase() }}</td>
                <td style="text-align: center;">{{ student.nome_segmento.toUpperCase() }}</td>
                <td style="text-align: center;">{{ student.nome_serie.toUpperCase() }}</td>
                <td style="text-align: center;">{{ student.nome_turma.toUpperCase() }}</td>
                <td style="text-align: center;">{{ student.vacinacao == 1 ? 'SIM' : 'NÃO' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

      
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import axios from "axios";
  import VaChart from '@/components/va-charts/VaChart'
  import calendarioVacinacao from "./components/filtros/filtroCalendarioVacinacao.vue";
  import { Aluno } from "@/pages/admin/pages/relatorios/services/aluno.service";

  export default defineComponent({
    components: { calendarioVacinacao, VaChart },
    data() {
      return {
        baseUrl: axios.defaults.baseURL.replace("/api/v1", ""),
        home: { icon: "pi pi-home", to: "/relatorios/calendario-vacinacao" },
        calendarioVacinacaoAtualizado: 0,
        calendarioVacinacaoNaoAtualizado:0,
        filtro: [
            {
            nome: "Nome",
            check: true,
            _id: 0,
            },
            {
            nome: "Filiação",
            check: true,
            _id: 1,
            },
        ],
        info: {
          escola: 'Rede de Ensino',
          segmento: '',
          serie: '',
          turma: ''
        },
        funcaoDoUsuario: '',
        listOfStudents: [],
        carregandoPrint : false
      };

    },

    computed: {
        chartData() {
            return {
                labels: [this.calendarioVacinacaoAtualizado + ' alunos atualizados', this.calendarioVacinacaoNaoAtualizado + ' alunos desatualizados'],
                datasets: [
                    {
                        label: 'Students',
                        backgroundColor: ['#42A5F5', '#FE9900'],
                        borderColor: 'transparent',
                        data: [this.calendarioVacinacaoAtualizado, this.calendarioVacinacaoNaoAtualizado],
                    }
                ],
            }
        },  
    },
  
    methods: {
        pdf() {
          this.carregandoPrint = true;
          setTimeout(() => { 
            document.getElementById('printme').innerHTML = '';
            const div = document.getElementById("print-vacinacao-calendario");
            const divp = document.getElementById("printme");
            if (divp.innerHTML) {
              divp.innerHTML = "";

            }
            if (div != null) {
              divp.innerHTML = div.innerHTML;
            }
            window.print(); 
          }, 1000);
          setTimeout(() => { this.carregandoPrint = false; }, 2000);
        },
        async limparFiltro() {
          await this.buscarRelatorio(null);
        },
        async buscarRelatorio(info) {
            this.info.escola = 'Rede de Ensino';
            this.info.segmento = '';
            this.info.serie = '';
            this.info.turma = '';
      
            let alunoInfoInt = {
                escola_id: 0,
                segmento_id: 0,
                serie_id: 0,
                turma_id: 0,
                ano: Number(sessionStorage.getItem("anoSelecionado"))
            }
            if (info != null) {
              alunoInfoInt = {
                  escola_id: Number(info.escola.id),
                  segmento_id: Number(info.segmento_id.id),
                  serie_id: Number(info.serie_id.id),
                  turma_id: Number(info.turma_id.id),
                  ano: Number(sessionStorage.getItem("anoSelecionado"))
              }
              if (alunoInfoInt.escola_id != 0) {
                this.info.escola = info.escola.nome;
              }
              else {
                this.info.escola = "Rede de Ensino";

              }
              if (alunoInfoInt.segmento_id != 0) {
                this.info.segmento = info.segmento_id.nome;
              }
              if (alunoInfoInt.serie_id != 0) {
                this.info.serie = info.serie_id.nome;
              }
              if (alunoInfoInt.turma_id != 0) {
                this.info.turma = info.turma_id.nome;
              }
            }
            
            Aluno.alunoVacinacao(alunoInfoInt)
            .then((resp) => {
                this.calendarioVacinacaoAtualizado = resp.data.vacinados;
                this.calendarioVacinacaoNaoAtualizado = resp.data.semVacinacao;
                this.listOfStudents = resp.data.listOfStudents;
            })
            .catch((err) => {
                const errorMessage = err.response.data.erro;
                this.$vaToast.init({
                    message: errorMessage,
                    iconClass: "fa-star-o",
                    position: "top-right",
                    color: "danger",
                    duration: 2500,
                    fullWidth: false,
                    });
            })
            .finally(() => {
                this.carregandoPagina = false;
            });
        },

    },
    async beforeMount() {
      this.funcaoDoUsuario = Number(sessionStorage.getItem("funcaoDoUsuario"));
      this.buscarRelatorio(null);
    },
  });
  </script>
  
  <style scoped>
    .student-list ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .button-generate {
      margin-top: 10px;
      margin-right: 5px;
    }
    .select {
      appearance: revert;
      width: 100%;
    }
    .cor-titulo {
      background-color: #292828 !important;
      color: #fff;
    }
    
    .uppercase {
      text-transform: uppercase;
    }
    .tamanho-titulos-dias {
      width: 3%;
    }
    .alinhamento {
      margin-top: -15px;
      padding: 14px;
    }
    
    .alinhameneto-margem {
      margin-left: -12px;
      margin-right: -12px;
    }
    
    .card-filtro {
      background-color: #f8f9fa;
      border: 1px solid #dee2e6;
      margin-top: 15px;
    }
  </style>
  
  <style>
    .border{
          border: 1px solid black;
    }
    
    .header{
      font-size: 14px;
      margin: 0px;
      padding: 0px;
    }
    
    #noprint {
      display: block;
    }
    #printme {
      display: none;
    }
    
    @media print {
      #noprint {
        display: none;
      }
      #printme {
        display: block;
      }
    }
  </style>
  